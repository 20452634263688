import {
  genericRouteGuardResult,
  parseUrlStringForAppId,
} from "@/router/routerFuncs"

const routes = [
  {
    path: "/sim-pipe",
    component: () => import("@apps/sim-pipe/src/App.vue"),
    children: [
      {
        path: "",
        name: "sim-pipe-home",
        meta: { title: "Sim Pipe" },
        component: () => import("@apps/sim-pipe/src/pages/sim-pipe-home.vue"),
      },
      {
        path: "logs",
        name: "sim-pipe-logs",
        meta: { title: "Logs" },
        component: () => import("@apps/sim-pipe/src/pages/LogViewer.vue"),
      },
      {
        path: "missing-data",
        name: "sim-pipe-missing-data",
        meta: { title: "Missing Data" },
        component: () =>
          import("@apps/sim-pipe/src/pages/MissingDataViewer.vue"),
      },
      {
        path: "api-data",
        name: "sim-pipe-api-data",
        meta: { title: "API Data" },
        component: () => import("@apps/sim-pipe/src/pages/JsonViewer.vue"),
      },
      {
        path: "/pre-api-data",
        name: "PreApiData",
        meta: { title: "Pre API Data" },
        component: () =>
          import("@apps/sim-pipe/src/pages/PreApiDataViewer.vue"),
      },
    ],
  },
]

const menuIndex = 1

const menuItems = [
  {
    icon: "Signal",
    pageName: "sim-pipe-home",
    title: "Sim Pipeline",
    /* subMenu: [
      {
        icon: "Bell",
        pageName: "sim-pipe_home",
        title: "Get started",
      },
      {
        icon: "Bell",
        pageName: "sim-pipe_table",
        title: "Packages",
      },
    ], */
  },
]

export const submoduleRouteGuard = (router: any) => {
  return async (to: any, from: any, next: any) => {
    // step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
    let guardRequirements = {
      appId: "sim-pipe",
      baseRoles: ["pipe-admin"],
    }
    let parsedAppID: string | null = parseUrlStringForAppId(to.path)
    if (parsedAppID === guardRequirements.appId) {
      // step 2 - inside of this submodule - check if the role is allowed/valid
      const routeGuardAllowed = await genericRouteGuardResult(guardRequirements)
      if (routeGuardAllowed) {
        next()
      } else {
        // abort navigation
        next("/") // Redirect to home page - show no access message
      }
    } else {
      next() // Allow other submodule routes and routes
    }
  }
}

export default {
  routes,
  menuIndex,
  menuItems,
}
